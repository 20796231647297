import axios from "axios"

const token = localStorage.getItem('accessToken') || null

const adminAxios = axios.create({
    headers: {
        Authorization: token,
    }
});

export default adminAxios