import PropTypes from 'prop-types';
// @mui
import { Typography, Stack } from '@mui/material';
// components
import { Logo } from '../../components/logo';
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (
    <StyledRoot>
      <StyledSection sx={{ width: '50%' }}>
        <Typography variant="h3" sx={{ mb: 6, textAlign: 'center' }}>
          {/* {title || 'Photokrafft Admin Panel'} */}
        </Typography>

        <Logo disabledLink sx={{ width: 400 }} />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent sx={{ width: '50%' }}>
        <Stack sx={{ width: "25em" }}> {children} </Stack>
      </StyledContent>

    </StyledRoot>
  );
}
