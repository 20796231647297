import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';

import customerReducer from './slices/customer';
import orderReducer from './slices/order';
import invoiceReducer from './slices/invoice';
import resourceReducer from './slices/resourceSlice';
import userReducer from './slices/user';
import notificationReducer from './slices/notification';
import AuthReducer from './slices/AuthSlice';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  customer: customerReducer,
  order: orderReducer,
  invoice: invoiceReducer,
  resource: resourceReducer,
  user: userReducer,
  notification: notificationReducer,
  auth:AuthReducer
});

export default rootReducer;
